@import "/src/scss/variables";

:root {
    --header-text-color: var(--white);
    --header-background-color: var(--deep-blue-gradient);
}

.section-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    color: var(--input-text-placeholder-color);
    background-color: var(--input-background-color);
    border-bottom: 0.5px solid var(--input-border-color);
    h1 {
        font-weight: 600;
        font-size: 16px;
        margin: 0;
    }
    p {
        font-size: 14px;
    }
}

@media (min-width: $tablet-breakpoint) {
    .section-header {
        padding: 24px;
 
        h1 {
            font-weight: 600;
            font-size: 18px;
        }
        p {
            font-size: 16px;
        }
    }
}

@media (min-width: $desktop-breakpoint) {
}
