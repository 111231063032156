$icomoon-font-family: "siemens-icons" !default;
$icomoon-font-path: "/assets/fonts/SiemensIcons" !default;

$icon-admin: "\e900";
$icon-apps: "\e901";
$icon-arrow-down: "\e902";
$icon-arrow-left: "\e903";
$icon-arrow-right: "\e904";
$icon-arrow-up: "\e905";
$icon-blog: "\e906";
$icon-bookmark: "\e907";
$icon-burger: "\e908";
$icon-calender: "\e909";
$icon-chat: "\e90a";
$icon-check: "\e90b";
$icon-close: "\e90c";
$icon-contacts: "\e90d";
$icon-distributor: "\e90e";
$icon-download: "\e90f";
$icon-dropdown: "\e910";
$icon-edit: "\e911";
$icon-email: "\e912";
$icon-eye: "\e913";
$icon-facebook: "\e914";
$icon-favorite: "\e915";
$icon-filter: "\e916";
$icon-full-screen: "\e917";
$icon-globe: "\e918";
$icon-help: "\e919";
$icon-history: "\e91a";
$icon-home: "\e91b";
$icon-image: "\e91c";
$icon-industry: "\e91d";
$icon-instagram: "\e91e";
$icon-internal_user: "\e91f";
$icon-like: "\e920";
$icon-link: "\e921";
$icon-linkedin: "\e922";
$icon-location: "\e923";
$icon-logout: "\e924";
$icon-microphone: "\e925";
$icon-minus: "\e926";
$icon-notification: "\e927";
$icon-options: "\e928";
$icon-pause: "\e929";
$icon-play: "\e92a";
$icon-plus: "\e92b";
$icon-reading-time: "\e92c";
$icon-refresh: "\e92d";
$icon-search: "\e92e";
$icon-settings: "\e92f";
$icon-share: "\e930";
$icon-shopping-cart: "\e931";
$icon-tools: "\e932";
$icon-twitter: "\e933";
$icon-user: "\e934";
$icon-warning: "\e935";
$icon-sie-logo: "\e936";
$icon-ellipsis-vertical: "f142";
