//  @use 'node_modules/@angular/material' as mat;// Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
//  //@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";



//  @include mat.core();

// /* Preferably put any custom styles for ngx-datatable into your own "_datatable.scss" file 
//    and @import it into global styles, but this is a simple working example */
//    $ng-si-ep-primary: mat.define-palette(mat.$indigo-palette, 500);
//    $ng-si-ep-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
   
//    // The "warn" palette is optional and defaults to red if not specified.
//    $ng-si-ep-warn: mat.define-palette(mat.$red-palette);
   
//    $ng-si-ep-theme: mat.define-light-theme((
//     color: (
//                     primary: $ng-si-ep-primary,
//                     accent: $ng-si-ep-accent,
//                     warn: $ng-si-ep-warn,
//                 ),
//     typography: mat.define-typography-config(),
//     density: 0,
//    ));
//    @include mat.core-theme( $ng-si-ep-theme);


.ngx-datatable {
    border: 1px solid #dddddd;
}
datatable-body-cell {
    text-align: center !important;
}

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
//  $ng-si-ep-primary: mat.define-palette(mat.$indigo-palette.50);
//  $ng-si-ep-accent: mat.define-palette(mat.$pink, A200, A100, A400);

// // // The warn palette is optional (defaults to red).
//  $ng-si-ep-warn: mat.define-palette(mat.$red-palette);

// // // Create the theme object. A theme consists of configurations for individual
// // // theming systems such as "color" or "typography".
// // $ng-si-ep-theme: mat.define-light-theme(
// //     (
// //         color: (
// //             primary: $ng-si-ep-primary,
// //             accent: $ng-si-ep-accent,
// //             warn: $ng-si-ep-warn,
// //         ),
// //     )
// // );
// $ng-si-ep-theme: mat.define-light-theme((
//     color: (
//         primary: $ng-si-ep-primary,
//         accent: $ng-si-ep-accent,
//         warn: $ng-si-ep-warn,
//     ),
//  typography: mat.define-typography-config(),
//  density: 0,
// ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include angular-material-theme($ng-si-ep-theme);

/* You can add global styles to this file, and also import other style files */
@import "./scss/reset";
@import "./scss/colors";
@import "./scss/icons/icons";
@import "./scss/fonts/siemens-sans-pro.scss";
@import "./scss/fonts/roboto-condensed.pro.scss";

* {
    font-family: "Siemens Sans Pro" !important;
}

html,
body {
    height: 100%;
}

@import "./scss/components/headers";
@import "./scss/components/tabs";
@import "./scss/components/navbars";
@import "./scss/components/inputs";
@import "./scss/components/forms";
@import "./scss/components/buttons";
@import "./scss/components/tables";
.ck-editor__editable {
    min-height: 416px;
}
.truncate {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.w-100 {
    width: 100%;
}

::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    width: 3px;

    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    width: 3px;

    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    width: 3px;

    background: #555;
}

.class-notification {
    a {
        .body {
            display: flex;
            align-items: center;
            img {
                width: 80px;
                height: 80px;
            }
            .text_info {
                display: block;
                .title {
                    color: var(--primary-green);
                    font-weight: 550;
                    margin-right: 5px;
                }
            }
        }
    }
}

.text-center {
    text-align: center;
}

.text-primary {
    color: var(--primary-green);
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 600;
}
mat-tab-body {
    padding: 10px;
}

mat-select {
    font-weight: 400;
    height: 40px;
    border-radius: 4px;
    border: 0.5px solid var(--input-border-color);
    color: var(--input-text-color);
    background-color: var(--input-background-color);
    padding: 10px 10px;
    position: relative;
}

.ep-message {
    mat-dialog-container {
        background-color: #000016;
        color: white;
        border-radius: 0px;
        // width: 80%;
        margin: auto;
    }
}
.inline {
    display: flex;
    align-items: center;
}
.mat-dialog-container {
    border-radius: 20px !important;
    background-color: white;
}

.map-container {
    width: 100% !important;
    height: 100% !important;
}

::-webkit-scrollbar {
    width: 1px;
}

.lang {
    &__list {
        padding: 5px;
        &__item {
            padding: 5px;
            border-bottom: 1px solid #888;
            &--active {
                background-color: papayawhip;
            }
            &:hover {
                background-color: wheat;
            }
        }
    }
}

.data__product__search {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    strong {
        color: var(--primary-green);
        border: 1px solid black;
        padding: 5px 10px;
        width: 100%;
        display: block;
    }
    p {
        color: black;
        border: 1px solid black;
        padding: 5px 10px;
        width: 100%;
    }
}

select {
    font-family: "Roboto Condensed" !important;
    font-weight: 400;
    height: 40px;
    border-radius: 4px;
    border: 0.5px solid var(--input-border-color);
    color: var(--input-text-color);
    background-color: var(--input-background-color);
    padding: 0 8px;
    position: relative;
}

table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-size: 12px;
  }
  
  table td, table th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  table tr:nth-child(even){background-color: #f2f2f2;}
  
  table tr:hover {background-color: #ddd;}
  
  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #474646;
    color: white;
  }
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

