:root {
    --primary-btn-color: var(--primary-green);
    --gray-button-color: #f0efef;
    --btn-text-color: var(--white);
    --btn-disabled-color: #b3ecf5;
}

.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: var(--btn-text-color);
    border-radius: 2px;
    padding: 16px;
    max-height: 40px;
    cursor: pointer;
    &--underlined {
        text-decoration: underline;
    }
    &--block {
        width: 100%;
    }
    &--danger {
        background-color:  #EF0137;
        
    }
    &--solid {
        box-shadow: 2px 5px 5px #f0efef;
        &--primary {
            background-color: var(--primary-btn-color);
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                content: " ";
                background-color: white;
                opacity: 0;
                transition: opacity 300ms ease-in;
            }
            &:disabled {
                background-color: var(--btn-disabled-color);
            }
            &:hover {
                &::after {
                    opacity: 0.3;
                }
            }
            &:focus {
                outline: 0;
                box-shadow: inset 0 0 0 2px var(--btn-disabled-color);
            }
        }
        &--gray {
            background-color: var(--gray-button-color);
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                content: " ";
                background-color: white;
                opacity: 0;
                transition: opacity 300ms ease-in;
            }
            &:disabled {
                background-color: var(--btn-disabled-color);
            }
            &:hover {
                &::after {
                    opacity: 0.3;
                }
            }
            &:focus {
                outline: 0;
                box-shadow: inset 0 0 0 2px var(--btn-disabled-color);
            }
        }
    }
    &--transparent {
        padding: 0;
        &--primary {
            background-color: transparent;
            color: var(--primary-btn-color);
            transition: color 200ms ease-in;
            &:disabled {
                color: var(--btn-disabled-color);
            }
            &:hover {
                color: var(--deep-blue);
            }
            &:focus {
                outline: 0;
            }
        }
        &--white {
            background-color: transparent;
            color: var(--white);
            &:disabled {
                color: var(--btn-disabled-color);
            }
        }
    }
    &--outline {
        &--primary {
            border: 2px solid;
            background-color: transparent;
            color: var(--primary-btn-color);
            border-color: var(--primary-btn-color);
            &:disabled {
                color: var(--btn-disabled-color);
                border-color: var(--btn-disabled-color);
            }
        }
    }
    &-back {
        background-color: var(--primary-green);
        border-radius: 50%;
    }
    &-icon {
        display: inline-flex;
        min-width: 40px;
        min-height: 40px;
        color: var(--white);
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}
