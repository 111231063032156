@import "/src/scss/variables";

.table {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-bottom: 20px;
    &__headers {
        display: grid;
        padding: 8px;
        margin-bottom: 4px;
    }
    &__header {
        font-size: 14px;
        color: var(--input-text-placeholder-color);
        font-weight: 600;
    }
    &__content {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: 90%;
        padding-bottom: 80px;
    }
    &__row {
        display: grid;
        padding: 10px;
        border-radius: 4px;
        background-color: var(--input-background-color);
        border: 0.5px solid var(--input-border-color);
        color: var(--input-text-color);
        box-shadow: 2px 5px 5px #f0efef;
        + .table__row {
            margin-top: 8px;
        }
    }
}

@media (min-width: $tablet-breakpoint) {
    .table {
        &__content {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(10, 260px);
            column-gap: 16px;
            row-gap: 16px;
            justify-content: center;
        }
        &__row {
            + .table__row {
                margin-top: 0 !important;
            }
        }
    }
}

@media (min-width: $desktop-breakpoint) {
    .table {
        &__row {
            padding: 16px;
        }
        &__headers {
            padding: 16px;
            font-size: 16px;
        }
        &__content {
            justify-content: start;
        }
    }
}
