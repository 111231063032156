:root {
    --input-border-color: var(--light-gray);
    --input-text-color: var(--dark-gray);
    --input-text-placeholder-color: var(--sutile-gray);
    --input-background-color: var(--sutile-light-gray);
    --active-input-border-shadow-color: var(--light-sutile-green);
    --active-input-border-color: var(--primary-green);
    --active-input-background-color: var(--light-green);
    --active-input-border: drop-shadow(
        0px 0px 8px var(--active-input-border-shadow-color)
    );
    --invalid-input-color: var(--primary-orange);
    --invalid-input-background-color: var(--light-orange);
    --invalid-input-border-color: var(--sutile-orange);
    --invalid-input-border: drop-shadow(
        0px 0px 8px var(--invalid-input-background-color)
    );
}

//Golbal input override
input {
    font-family: "Roboto Condensed" !important;
    font-weight: 400;
    height: 40px;
    border-radius: 4px;
    border: 0.5px solid var(--input-border-color);
    color: var(--input-text-color);
    background-color: var(--input-background-color);
    padding: 0 8px;
    position: relative;
    &::placeholder {
        color: var(--input-text-placeholder-color);
        font-weight: 300;
    }
    &:focus,
    &:active {
        border: 0.5px solid var(--active-input-border-color);
        box-shadow: 0px 0px 8px var(--active-input-border-shadow-color);
        background-color: var(--active-input-background-color);
        outline: none;
    }
    &.ng-invalid.ng-touched {
        border: 0.5px solid var(--invalid-input-border-color);
        box-shadow: 0px 0px 8px var(--invalid-input-background-color);
        background-color: var(--invalid-input-background-color);
        color: var(--invalid-input-color);
    }
}

.input {
    &__icon {
        font-size: 20px;
        display: inline-flex;
        align-items: center;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        color: black;
        opacity: 0.6;
        margin-right: 8px;
    }
    input {
        width: 100%;
    }
    &.opened {
        i {
            transform: rotate(180deg);
        }
    }
}

.dropdown-input {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    .dropdown__options {
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        position: absolute;
        top: 53px;
        margin-top: 4px;
        z-index: 1000;
        background-color: var(--input-background-color);
        border: 0.5px solid var(--input-border-color);
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
    }
    .dropdown__option {
        display: inline-flex;
        color: var(--input-text-color);
        background-color: var(--input-background-color);
        padding: 12px;
        font-family: "Roboto Condensed";
        font-size: 12px;
        max-height: 40px;
        cursor: pointer;
        &:hover {
            background-color: var(--active-input-background-color);
        }
        &--no-items {
            @extend .dropdown__option;
            color: var(--dark-gray);
        }
    }
}
