:root {
    --deep-blue: #000028;
    --pantone: #009999;
    --white: white;
    --primary-green: #00bedc;
    --dark-green: #2387aa;
    --light-sutile-green: #b3ebf5;
    --light-green: #e7f1f5;
    --light-gray: #d7d7d7;
    --sutile-light-gray: #f3f3f0;;
    --ligth-gray-background: #f1f1f1;
    --dark-gray: #474646;
    --sutile-gray: #838383;
    --tags-gray: #7F7F7F;
    --tags-background: #F0EFEF;
    --primary-orange: #faa50a;
    --light-orange: #fff6e7;
    --sutile-orange: #fccd79;
    --deep-blue-gradient: linear-gradient(
        180deg,
        var(--deep-blue) 0%,
        var(--pantone) 100%
    );
}
