.ngx-datatable {
  border: 1px solid #dddddd;
}

datatable-body-cell {
  text-align: center !important;
}

/* You can add global styles to this file, and also import other style files */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

.ck-content p,
.ck-content strong,
.ck-content h1,
.ck-content h2,
.ck-content h3,
.ck-content h4,
.ck-content strong,
.ck-content table,
.ck-content ul,
.ck-content ol,
.ck-content a,
.ck-content i {
  all: revert !important;
  list-style: revert !important;
  text-decoration: revert !important;
}
.ck-content .table {
  height: auto !important;
  margin: auto !important;
}
@media (max-width: 768px) {
  .ck-content .table td,
  .ck-content .table tr {
    display: block;
    border: none !important;
  }
}
.ck-content .table td,
.ck-content .table tr {
  border: none !important;
}
.ck-content .ck-content .image.image_resized {
  width: auto;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  image-rendering: -webkit-optimize-contrast;
}

button {
  margin: 0;
  padding: 0;
  border: none;
}

a {
  text-decoration: none;
  color: inherit;
}

:root {
  --deep-blue: #000028;
  --pantone: #009999;
  --white: white;
  --primary-green: #00bedc;
  --dark-green: #2387aa;
  --light-sutile-green: #b3ebf5;
  --light-green: #e7f1f5;
  --light-gray: #d7d7d7;
  --sutile-light-gray: #f3f3f0;
  --ligth-gray-background: #f1f1f1;
  --dark-gray: #474646;
  --sutile-gray: #838383;
  --tags-gray: #7F7F7F;
  --tags-background: #F0EFEF;
  --primary-orange: #faa50a;
  --light-orange: #fff6e7;
  --sutile-orange: #fccd79;
  --deep-blue-gradient: linear-gradient(
      180deg,
      var(--deep-blue) 0%,
      var(--pantone) 100%
  );
}

@font-face {
  font-family: "siemens-icons";
  src: url("/assets/fonts/SiemensIcons/siemens-icons.eot?lg8cav");
  src: url("/assets/fonts/SiemensIcons/siemens-icons.eot?lg8cav#iefix") format("embedded-opentype"), url("/assets/fonts/SiemensIcons/siemens-icons.ttf?lg8cav") format("truetype"), url("/assets/fonts/SiemensIcons/siemens-icons.woff?lg8cav") format("woff"), url("/assets/fonts/SiemensIcons/siemens-icons.svg?lg8cav#siemens-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "siemens-web-icons";
  src: url("/Siemens-Web-Icons-3-5-0.eot?l9aym1");
  src: url("/assets/fonts/SiemensV2/Siemens-Web-Icons-3-5-0.eot?l9aym1#iefix") format("embedded-opentype"), url("/assets/fonts/SiemensV2/Siemens-Web-Icons-3-5-0.ttf?l9aym1") format("truetype"), url("/assets/fonts/SiemensV2/Siemens-Web-Icons-3-5-0.woff?l9aym1") format("woff"), url("/assets/fonts/SiemensV2/Siemens-Web-Icons-3-5-0.svg?l9aym1#Siemens-Web-Icons-3-5-0") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "siemens-icons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-information {
  font-family: "siemens-web-icons" !important;
}
.icon-information:before {
  content: "\e91b";
}

.icon-link-extern {
  font-family: "siemens-web-icons" !important;
}
.icon-link-extern:before {
  content: "\e91d";
}

.icon-monitor {
  font-family: "siemens-web-icons" !important;
}
.icon-monitor:before {
  content: "\e968";
}

.icon-capacity {
  font-family: "siemens-web-icons" !important;
}
.icon-capacity:before {
  content: "\e95d";
}

.icon-question {
  font-family: "siemens-web-icons" !important;
}
.icon-question:before {
  content: "\e94e";
}

.icon-trash {
  font-family: "siemens-web-icons" !important;
}
.icon-trash:before {
  content: "\e959";
}

.icon-phone {
  font-family: "siemens-web-icons" !important;
}
.icon-phone:before {
  content: "\e92a";
}

.icon-language {
  font-family: "siemens-web-icons" !important;
}
.icon-language:before {
  content: "\e917";
}

.icon-login {
  font-family: "siemens-web-icons" !important;
}
.icon-login:before {
  content: "\e91e";
}

.icon-share {
  font-family: "siemens-web-icons" !important;
}
.icon-share:before {
  content: "\e931";
}

.icon-admin:before {
  content: "\e900";
}

.icon-apps:before {
  content: "\e901";
}

.icon-arrow-down:before {
  content: "\e902";
}

.icon-arrow-left:before {
  content: "\e903";
}

.icon-arrow-right:before {
  content: "\e904";
}

.icon-arrow-up:before {
  content: "\e905";
}

.icon-blog:before {
  content: "\e906";
}

.icon-bookmark:before {
  content: "\e907";
}

.icon-burger:before {
  content: "\e908";
}

.icon-calender:before {
  content: "\e909";
}

.icon-chat:before {
  content: "\e90a";
}

.icon-check:before {
  content: "\e90b";
}

.icon-close:before {
  content: "\e90c";
}

.icon-contacts:before {
  content: "\e90d";
}

.icon-distributor:before {
  content: "\e90e";
}

.icon-download:before {
  content: "\e90f";
}

.icon-dropdown:before {
  content: "\e910";
}

.icon-edit:before {
  content: "\e911";
}

.icon-email:before {
  content: "\e912";
}

.icon-eye:before {
  content: "\e913";
}

.icon-facebook:before {
  content: "\e914";
}

.icon-favorite:before {
  content: "\e915";
}

.icon-filter:before {
  content: "\e916";
}

.icon-full-screen:before {
  content: "\e917";
}

.icon-globe:before {
  content: "\e918";
}

.icon-help:before {
  content: "\e919";
}

.icon-history:before {
  content: "\e91a";
}

.icon-home:before {
  content: "\e91b";
}

.icon-image:before {
  content: "\e91c";
}

.icon-industry:before {
  content: "\e91d";
}

.icon-instagram:before {
  content: "\e91e";
}

.icon-internal_user:before {
  content: "\e91f";
}

.icon-like:before {
  content: "\e920";
}

.icon-link:before {
  content: "\e921";
}

.icon-linkedin:before {
  content: "\e922";
}

.icon-location:before {
  content: "\e923";
}

.icon-logout:before {
  content: "\e924";
}

.icon-microphone:before {
  content: "\e925";
}

.icon-minus:before {
  content: "\e926";
}

.icon-notification:before {
  content: "\e927";
}

.icon-options:before {
  content: "\e928";
}

.icon-pause:before {
  content: "\e929";
}

.icon-play:before {
  content: "\e92a";
}

.icon-plus:before {
  content: "\e92b";
}

.icon-reading-time:before {
  content: "\e92c";
}

.icon-refresh:before {
  content: "\e92d";
}

.icon-search:before {
  content: "\e92e";
}

.icon-settings:before {
  content: "\e92f";
}

.icon-shopping-cart:before {
  content: "\e931";
}

.icon-tools:before {
  content: "\e932";
}

.icon-twitter:before {
  content: "\e933";
}

.icon-user:before {
  content: "\e934";
}

.icon-warning:before {
  content: "\e935";
}

.icon-sie-logo:before {
  content: "\e936";
}

.icon-ellipsis-vertical:before {
  content: "f142";
}

@font-face {
  font-family: "Siemens Sans Pro";
  src: url("/assets/fonts/SiemensSansPro//SiemensSans_Prof_Black.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Siemens Sans Pro";
  src: url("/assets/fonts/SiemensSansPro//SiemensSans_Prof_Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Siemens Sans Pro";
  src: url("/assets/fonts/SiemensSansPro//SiemensSans_Prof_Roman.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("/assets/fonts/RobotoCondensed/RobotoCondensed-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("/assets/fonts/RobotoCondensed/RobotoCondensed-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("/assets/fonts/RobotoCondensed/RobotoCondensed-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
* {
  font-family: "Siemens Sans Pro" !important;
}

html,
body {
  height: 100%;
}

:root {
  --header-text-color: var(--white);
  --header-background-color: var(--deep-blue-gradient);
}

.section-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  color: var(--input-text-placeholder-color);
  background-color: var(--input-background-color);
  border-bottom: 0.5px solid var(--input-border-color);
}
.section-header h1 {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}
.section-header p {
  font-size: 14px;
}

@media (min-width: 768px) {
  .section-header {
    padding: 24px;
  }
  .section-header h1 {
    font-weight: 600;
    font-size: 18px;
  }
  .section-header p {
    font-size: 16px;
  }
}
.mat-tab-group .mat-tab-label-content {
  font-weight: 600;
}
.mat-tab-group .mat-tab-body-wrapper {
  height: 100%;
}

:root {
  --nav-background-color: var(--deep-blue);
  --nav-menu-width: 156px;
  --nav-action-link-width: calc($nav-menu-width / 3);
}

:root {
  --input-border-color: var(--light-gray);
  --input-text-color: var(--dark-gray);
  --input-text-placeholder-color: var(--sutile-gray);
  --input-background-color: var(--sutile-light-gray);
  --active-input-border-shadow-color: var(--light-sutile-green);
  --active-input-border-color: var(--primary-green);
  --active-input-background-color: var(--light-green);
  --active-input-border: drop-shadow(
      0px 0px 8px var(--active-input-border-shadow-color)
  );
  --invalid-input-color: var(--primary-orange);
  --invalid-input-background-color: var(--light-orange);
  --invalid-input-border-color: var(--sutile-orange);
  --invalid-input-border: drop-shadow(
      0px 0px 8px var(--invalid-input-background-color)
  );
}

input {
  font-family: "Roboto Condensed" !important;
  font-weight: 400;
  height: 40px;
  border-radius: 4px;
  border: 0.5px solid var(--input-border-color);
  color: var(--input-text-color);
  background-color: var(--input-background-color);
  padding: 0 8px;
  position: relative;
}
input::placeholder {
  color: var(--input-text-placeholder-color);
  font-weight: 300;
}
input:focus, input:active {
  border: 0.5px solid var(--active-input-border-color);
  box-shadow: 0px 0px 8px var(--active-input-border-shadow-color);
  background-color: var(--active-input-background-color);
  outline: none;
}
input.ng-invalid.ng-touched {
  border: 0.5px solid var(--invalid-input-border-color);
  box-shadow: 0px 0px 8px var(--invalid-input-background-color);
  background-color: var(--invalid-input-background-color);
  color: var(--invalid-input-color);
}

.input__icon {
  font-size: 20px;
  display: inline-flex;
  align-items: center;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  color: black;
  opacity: 0.6;
  margin-right: 8px;
}
.input input {
  width: 100%;
}
.input.opened i {
  transform: rotate(180deg);
}

.dropdown-input {
  display: inline-flex;
  flex-direction: column;
  position: relative;
}
.dropdown-input .dropdown__options {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  position: absolute;
  top: 53px;
  margin-top: 4px;
  z-index: 1000;
  background-color: var(--input-background-color);
  border: 0.5px solid var(--input-border-color);
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}
.dropdown-input .dropdown__option, .dropdown-input .dropdown__option--no-items {
  display: inline-flex;
  color: var(--input-text-color);
  background-color: var(--input-background-color);
  padding: 12px;
  font-family: "Roboto Condensed";
  font-size: 12px;
  max-height: 40px;
  cursor: pointer;
}
.dropdown-input .dropdown__option:hover, .dropdown-input .dropdown__option--no-items:hover {
  background-color: var(--active-input-background-color);
}
.dropdown-input .dropdown__option--no-items {
  color: var(--dark-gray);
}

.form__input {
  display: flex;
  flex-direction: column;
}
.form__input label {
  color: var(--primary-green);
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 600;
}
.form__input .error {
  color: var(--invalid-input-color);
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
}
.form__group {
  margin-left: 15px;
}
.form__group label {
  color: var(--primary-green);
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 600;
}

:root {
  --primary-btn-color: var(--primary-green);
  --gray-button-color: #f0efef;
  --btn-text-color: var(--white);
  --btn-disabled-color: #b3ecf5;
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: var(--btn-text-color);
  border-radius: 2px;
  padding: 16px;
  max-height: 40px;
  cursor: pointer;
}
.btn--underlined {
  text-decoration: underline;
}
.btn--block {
  width: 100%;
}
.btn--danger {
  background-color: #EF0137;
}
.btn--solid {
  box-shadow: 2px 5px 5px #f0efef;
}
.btn--solid--primary {
  background-color: var(--primary-btn-color);
}
.btn--solid--primary::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: " ";
  background-color: white;
  opacity: 0;
  transition: opacity 300ms ease-in;
}
.btn--solid--primary:disabled {
  background-color: var(--btn-disabled-color);
}
.btn--solid--primary:hover::after {
  opacity: 0.3;
}
.btn--solid--primary:focus {
  outline: 0;
  box-shadow: inset 0 0 0 2px var(--btn-disabled-color);
}
.btn--solid--gray {
  background-color: var(--gray-button-color);
}
.btn--solid--gray::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: " ";
  background-color: white;
  opacity: 0;
  transition: opacity 300ms ease-in;
}
.btn--solid--gray:disabled {
  background-color: var(--btn-disabled-color);
}
.btn--solid--gray:hover::after {
  opacity: 0.3;
}
.btn--solid--gray:focus {
  outline: 0;
  box-shadow: inset 0 0 0 2px var(--btn-disabled-color);
}
.btn--transparent {
  padding: 0;
}
.btn--transparent--primary {
  background-color: transparent;
  color: var(--primary-btn-color);
  transition: color 200ms ease-in;
}
.btn--transparent--primary:disabled {
  color: var(--btn-disabled-color);
}
.btn--transparent--primary:hover {
  color: var(--deep-blue);
}
.btn--transparent--primary:focus {
  outline: 0;
}
.btn--transparent--white {
  background-color: transparent;
  color: var(--white);
}
.btn--transparent--white:disabled {
  color: var(--btn-disabled-color);
}
.btn--outline--primary {
  border: 2px solid;
  background-color: transparent;
  color: var(--primary-btn-color);
  border-color: var(--primary-btn-color);
}
.btn--outline--primary:disabled {
  color: var(--btn-disabled-color);
  border-color: var(--btn-disabled-color);
}
.btn-back {
  background-color: var(--primary-green);
  border-radius: 50%;
}
.btn-icon {
  display: inline-flex;
  min-width: 40px;
  min-height: 40px;
  color: var(--white);
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.table {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-bottom: 20px;
}
.table__headers {
  display: grid;
  padding: 8px;
  margin-bottom: 4px;
}
.table__header {
  font-size: 14px;
  color: var(--input-text-placeholder-color);
  font-weight: 600;
}
.table__content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 90%;
  padding-bottom: 80px;
}
.table__row {
  display: grid;
  padding: 10px;
  border-radius: 4px;
  background-color: var(--input-background-color);
  border: 0.5px solid var(--input-border-color);
  color: var(--input-text-color);
  box-shadow: 2px 5px 5px #f0efef;
}
.table__row + .table__row {
  margin-top: 8px;
}

@media (min-width: 768px) {
  .table__content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(10, 260px);
    column-gap: 16px;
    row-gap: 16px;
    justify-content: center;
  }
  .table__row + .table__row {
    margin-top: 0 !important;
  }
}
@media (min-width: 1200px) {
  .table__row {
    padding: 16px;
  }
  .table__headers {
    padding: 16px;
    font-size: 16px;
  }
  .table__content {
    justify-content: start;
  }
}
.ck-editor__editable {
  min-height: 416px;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.w-100 {
  width: 100%;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  width: 3px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 3px;
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 3px;
  background: #555;
}

.class-notification a .body {
  display: flex;
  align-items: center;
}
.class-notification a .body img {
  width: 80px;
  height: 80px;
}
.class-notification a .body .text_info {
  display: block;
}
.class-notification a .body .text_info .title {
  color: var(--primary-green);
  font-weight: 550;
  margin-right: 5px;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-green);
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 600;
}

mat-tab-body {
  padding: 10px;
}

mat-select {
  font-weight: 400;
  height: 40px;
  border-radius: 4px;
  border: 0.5px solid var(--input-border-color);
  color: var(--input-text-color);
  background-color: var(--input-background-color);
  padding: 10px 10px;
  position: relative;
}

.ep-message mat-dialog-container {
  background-color: #000016;
  color: white;
  border-radius: 0px;
  margin: auto;
}

.inline {
  display: flex;
  align-items: center;
}

.mat-dialog-container {
  border-radius: 20px !important;
  background-color: white;
}

.map-container {
  width: 100% !important;
  height: 100% !important;
}

::-webkit-scrollbar {
  width: 1px;
}

.lang__list {
  padding: 5px;
}
.lang__list__item {
  padding: 5px;
  border-bottom: 1px solid #888;
}
.lang__list__item--active {
  background-color: papayawhip;
}
.lang__list__item:hover {
  background-color: wheat;
}

.data__product__search {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.data__product__search strong {
  color: var(--primary-green);
  border: 1px solid black;
  padding: 5px 10px;
  width: 100%;
  display: block;
}
.data__product__search p {
  color: black;
  border: 1px solid black;
  padding: 5px 10px;
  width: 100%;
}

select {
  font-family: "Roboto Condensed" !important;
  font-weight: 400;
  height: 40px;
  border-radius: 4px;
  border: 0.5px solid var(--input-border-color);
  color: var(--input-text-color);
  background-color: var(--input-background-color);
  padding: 0 8px;
  position: relative;
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #474646;
  color: white;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}