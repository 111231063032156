@import "./icon-variables";

@font-face {
    font-family: "#{$icomoon-font-family}";
    src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?lg8cav");
    src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?lg8cav#iefix")
            format("embedded-opentype"),
        url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?lg8cav")
            format("truetype"),
        url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?lg8cav")
            format("woff"),
        url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?lg8cav##{$icomoon-font-family}")
            format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: "siemens-web-icons";
    src: url("/Siemens-Web-Icons-3-5-0.eot?l9aym1");
    src: url("/assets/fonts/SiemensV2/Siemens-Web-Icons-3-5-0.eot?l9aym1#iefix")
            format("embedded-opentype"),
        url("/assets/fonts/SiemensV2/Siemens-Web-Icons-3-5-0.ttf?l9aym1")
            format("truetype"),
        url("/assets/fonts/SiemensV2/Siemens-Web-Icons-3-5-0.woff?l9aym1")
            format("woff"),
        url("/assets/fonts/SiemensV2/Siemens-Web-Icons-3-5-0.svg?l9aym1#Siemens-Web-Icons-3-5-0")
            format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "#{$icomoon-font-family}" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-information {
    font-family: "siemens-web-icons" !important;
    &:before {
        content: "\e91b";
    }
}
.icon-link-extern {
    font-family: "siemens-web-icons" !important;
    &:before {
        content: "\e91d";
    }
}
.icon-monitor {
    font-family: "siemens-web-icons" !important;
    &:before {
        content: "\e968";
    }
}
.icon-capacity {
    font-family: "siemens-web-icons" !important;
    &:before {
        content: "\e95d";
    }
}
.icon-question {
    font-family: "siemens-web-icons" !important;
    &:before {
        content: "\e94e";
    }
}
.icon-trash {
    font-family: "siemens-web-icons" !important;
    &:before {
        content: "\e959";
    }
}

.icon-phone {
    font-family: "siemens-web-icons" !important;
    &:before {
        content: "\e92a";
    }
}.icon-language {
    font-family: "siemens-web-icons" !important;
    &:before {
        content: "\e917";
    }
}
.icon-login {
    font-family: "siemens-web-icons" !important;
    &:before {
        content: "\e91e";
    }
}
.icon-share {
    font-family: "siemens-web-icons" !important;

    &:before {
    content: '\e931';
    }
}


.icon-admin {
    &:before {
        content: $icon-admin;
    }
}
.icon-apps {
    &:before {
        content: $icon-apps;
    }
}
.icon-arrow-down {
    &:before {
        content: $icon-arrow-down;
    }
}
.icon-arrow-left {
    &:before {
        content: $icon-arrow-left;
    }
}
.icon-arrow-right {
    &:before {
        content: $icon-arrow-right;
    }
}
.icon-arrow-up {
    &:before {
        content: $icon-arrow-up;
    }
}
.icon-blog {
    &:before {
        content: $icon-blog;
    }
}
.icon-bookmark {
    &:before {
        content: $icon-bookmark;
    }
}
.icon-burger {
    &:before {
        content: $icon-burger;
    }
}
.icon-calender {
    &:before {
        content: $icon-calender;
    }
}
.icon-chat {
    &:before {
        content: $icon-chat;
    }
}
.icon-check {
    &:before {
        content: $icon-check;
    }
}
.icon-close {
    &:before {
        content: $icon-close;
    }
}
.icon-contacts {
    &:before {
        content: $icon-contacts;
    }
}
.icon-distributor {
    &:before {
        content: $icon-distributor;
    }
}
.icon-download {
    &:before {
        content: $icon-download;
    }
}
.icon-dropdown {
    &:before {
        content: $icon-dropdown;
    }
}
.icon-edit {
    &:before {
        content: $icon-edit;
    }
}
.icon-email {
    &:before {
        content: $icon-email;
    }
}
.icon-eye {
    &:before {
        content: $icon-eye;
    }
}
.icon-facebook {
    &:before {
        content: $icon-facebook;
    }
}
.icon-favorite {
    &:before {
        content: $icon-favorite;
    }
}
.icon-filter {
    &:before {
        content: $icon-filter;
    }
}
.icon-full-screen {
    &:before {
        content: $icon-full-screen;
    }
}
.icon-globe {
    &:before {
        content: $icon-globe;
    }
}
.icon-help {
    &:before {
        content: $icon-help;
    }
}
.icon-history {
    &:before {
        content: $icon-history;
    }
}
.icon-home {
    &:before {
        content: $icon-home;
    }
}
.icon-image {
    &:before {
        content: $icon-image;
    }
}
.icon-industry {
    &:before {
        content: $icon-industry;
    }
}
.icon-instagram {
    &:before {
        content: $icon-instagram;
    }
}
.icon-internal_user {
    &:before {
        content: $icon-internal_user;
    }
}
.icon-like {
    &:before {
        content: $icon-like;
    }
}
.icon-link {
    &:before {
        content: $icon-link;
    }
}
.icon-linkedin {
    &:before {
        content: $icon-linkedin;
    }
}
.icon-location {
    &:before {
        content: $icon-location;
    }
}
.icon-logout {
    &:before {
        content: $icon-logout;
    }
}
.icon-microphone {
    &:before {
        content: $icon-microphone;
    }
}
.icon-minus {
    &:before {
        content: $icon-minus;
    }
}
.icon-notification {
    &:before {
        content: $icon-notification;
    }
}
.icon-options {
    &:before {
        content: $icon-options;
    }
}
.icon-pause {
    &:before {
        content: $icon-pause;
    }
}
.icon-play {
    &:before {
        content: $icon-play;
    }
}
.icon-plus {
    &:before {
        content: $icon-plus;
    }
}
.icon-reading-time {
    &:before {
        content: $icon-reading-time;
    }
}
.icon-refresh {
    &:before {
        content: $icon-refresh;
    }
}
.icon-search {
    &:before {
        content: $icon-search;
    }
}
.icon-settings {
    &:before {
        content: $icon-settings;
    }
}

.icon-shopping-cart {
    &:before {
        content: $icon-shopping-cart;
    }
}
.icon-tools {
    &:before {
        content: $icon-tools;
    }
}
.icon-twitter {
    &:before {
        content: $icon-twitter;
    }
}
.icon-user {
    &:before {
        content: $icon-user;
    }
}
.icon-warning {
    &:before {
        content: $icon-warning;
    }
}
.icon-sie-logo {
    &:before {
        content: $icon-sie-logo;
    }
}
.icon-ellipsis-vertical {
    &:before {
        content: $icon-ellipsis-vertical;
    }
}
