$roboto-condensed-font-path: "/assets/fonts/RobotoCondensed" !default;

@font-face {
    font-family: "Roboto Condensed";
    src: url("#{$roboto-condensed-font-path}/RobotoCondensed-Regular.ttf")
        format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Roboto Condensed";
    src: url("#{$roboto-condensed-font-path}/RobotoCondensed-Light.ttf")
        format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Roboto Condensed";
    src: url("#{$roboto-condensed-font-path}/RobotoCondensed-Bold.ttf")
        format("truetype");
    font-weight: 700;
    font-style: normal;
}
