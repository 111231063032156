@import "/src/scss/variables";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
}

.ck-content {
    p,
    strong,
    h1,
    h2,
    h3,
    h4,
    strong,
    table,
    ul,
    ol,
    a,
    i {
        all: revert !important;
        list-style: revert !important;
        text-decoration: revert !important;
    }
    .table {
        height: auto !important;
        margin: auto !important;
        @media (max-width: $tablet-breakpoint) {
            td,
            tr {
                display: block;
                border: none !important;
            }
        }
        td,
        tr {
            border: none !important;
        }
    }
    .ck-content .image.image_resized {
        width: auto;
    }
    // figure {
    //     all: revert !important;
    // }
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    image-rendering: -webkit-optimize-contrast;
}

button {
    margin: 0;
    padding: 0;
    border: none;
}
a {
    text-decoration: none;
    color: inherit;
}
