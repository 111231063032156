$siemens-sans-pro-font-path: "/assets/fonts/SiemensSansPro/" !default;

@font-face {
    font-family: "Siemens Sans Pro";
    src: url("#{$siemens-sans-pro-font-path}/SiemensSans_Prof_Black.ttf")
        format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Siemens Sans Pro";
    src: url("#{$siemens-sans-pro-font-path}/SiemensSans_Prof_Bold.ttf")
        format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Siemens Sans Pro";
    src: url("#{$siemens-sans-pro-font-path}/SiemensSans_Prof_Roman.ttf")
        format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
