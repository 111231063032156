.form {
    &__input {
        display: flex;
        flex-direction: column;
        label {
            color: var(--primary-green);
            font-size: 14px;
            margin-bottom: 8px;
            font-weight: 600;
        }
        .error {
            color: var(--invalid-input-color);
            font-size: 12px;
            font-weight: 400;
            margin-top: 8px;
        }
    }
    &__group {
        label {
            color: var(--primary-green);
            font-size: 14px;
            margin-bottom: 8px;
            font-weight: 600;
        }
        margin-left: 15px;
    }
}
